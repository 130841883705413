import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import GetRetirementInvestorGoalCalculatorQuery
  from '@/modules/flagship/retirement-investor-goal-calculator/application/queries/get-retirement-investor-goal-calculator-query';
import EditRetirementFundGoalPlanCurrentAmountService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-amount-service';
import EditRetirementFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-plan-service';
import EditRetirementFundGoalPlanRetirementAgeService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-retirement-age-service';
import EditRetirementFundGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-define-by-service';

// Domain
import { RetirementInvestorGoalCalculatorDto }
  from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-investor-goal-calculator-dto';
import { DefineByEntity }
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/domain/entities/define-by-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EditRetirementFundDefineByViewModel {
  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly get_retirement_investor_goal_calculator_query!:
    GetRetirementInvestorGoalCalculatorQuery;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
  private readonly current_amount_service!: EditRetirementFundGoalPlanCurrentAmountService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditRetirementFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_RETIREMENT_AGE_SERVICE)
  private readonly retirement_age_service!: EditRetirementFundGoalPlanRetirementAgeService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditRetirementFundGoalPlanDefineByService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-retirement-fund-goal-plan.edit_retirement_fund_define_by';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  timer?: NodeJS.Timer;

  readonly min_contribution = 500;

  defined_by = 'by_time';

  contribution = '100';

  monthly_desired_pension = '';

  initial_amount = 0;

  target_amount_by_pension = 0;

  target_amount_by_contribution = 0;

  retirement_range_adjusted_by_pension = 0;

  retirement_range_adjusted_by_contribution = 0;

  pension_range_adjusted = 0;

  is_define_by_contribution = false;

  is_valid_form = false;

  retirement_age = 0;

  is_loading = false;

  is_min_pension_loading = false;

  investor_profile_id = '';

  associated_product_id = '';

  monthly_required_amount = 0;

  calculated_pension = 0;

  retirement_goal_calculator_dto: RetirementInvestorGoalCalculatorDto = {
    associated_product_id: '',
    defined_by: '',
    issued_age: 0,
    initial_amount: 0,
    retirement_age_adjusted: 0,
    monthly_pension_adjusted: 0,
    monthly_required_amount: 0,
  };

  min_pension = 1;

  input_rules = {
    contribution: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$500.00 MXN', this.min_contribution)],
    pension: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), this.getAmountFormatted(this.min_pension), this.min_pension)],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    this.setInitialValues();
    await this.loadMinPension();
    this.delay();
  }

  get target_amount_formatted() {
    return this.getAmountFormatted((this.is_define_by_contribution) ? this
      .target_amount_by_contribution : this.target_amount_by_pension);
  }

  get current_amount_formatted() {
    return this.getAmountFormatted(this.initial_amount);
  }

  get you_must_save() {
    return this.getAmountFormatted((this.is_define_by_contribution) ? this
      .calculated_pension : this.amount_you_must_save);
  }

  get amount_you_must_save() {
    return this.parseCurrencyToNumber(this.monthly_required_amount.toFixed(2));
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading || this.is_min_pension_loading;
  }

  getCustomClassOpacity = (is_option_by_time: boolean) => {
    this.defined_by = (!this.is_define_by_contribution) ? 'by_time' : 'by_contribution';
    if ((this.is_define_by_contribution && is_option_by_time) || (!this
      .is_define_by_contribution && !is_option_by_time)) return 'opacity';
    return '';
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getRetirementAgeInformation = () => (this.retirement_age_service.getRetirementAgeInformation());

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getCurrentAmountInformation = () => (this.current_amount_service.getCurrentAmountInformation());

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  setDefineByInformation = (define_by: DefineByEntity) => {
    this.define_by_service.setDefineByInformation(define_by);
  };

  delay = () => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      if (this.is_valid_form) {
        await this.loadMonthlyRequiredAmount();
      } else this.is_loading = false;
    }, 2000);
  }

  setInitialValues = () => {
    const budget_information = this.getRetirementAgeInformation();
    const current_plan = this.getCurrentPlanInformation();
    const current_amount = this.getCurrentAmountInformation();
    const define_by = this.getDefineByInformation();
    this.retirement_age = budget_information.retirement_age;
    this.initial_amount = this.parseCurrencyToNumber(current_plan.current_amount);
    if (current_amount.there_is_more_savings === 'yes') {
      this.initial_amount += current_amount.amount_added;
    }
    if (define_by.by_pension.contribution > 0) {
      this.monthly_desired_pension = String(define_by.by_pension.contribution);
      this.monthly_required_amount = define_by.by_pension.you_must_save;
      this.target_amount_by_pension = define_by.by_pension.accumulated_amount;
      this.retirement_range_adjusted_by_pension = define_by.by_pension.retirement_range_adjusted;
      this.is_define_by_contribution = define_by.is_defined_by_contribution;
      this.contribution = String(define_by.by_contribution.contribution);
      this.calculated_pension = define_by.by_contribution.you_must_save;
      this.target_amount_by_contribution = define_by.by_contribution.accumulated_amount;
      this.retirement_range_adjusted_by_contribution = define_by
        .by_contribution.retirement_range_adjusted;
      this.pension_range_adjusted = define_by.pension_range_adjusted;
    }
    this.retirement_goal_calculator_dto = {
      associated_product_id: current_plan.associated_product_id,
      defined_by: (this.is_define_by_contribution) ? 'contribution' : 'pension',
      issued_age: budget_information.current_age,
      retirement_age_adjusted: budget_information.retirement_age,
      initial_amount: this.initial_amount,
      monthly_pension_adjusted: 0,
      monthly_required_amount: 0,
    };
  }

  loadMinPension = async () => {
    try {
      this.is_min_pension_loading = true;
      const get_min_pension_dto: RetirementInvestorGoalCalculatorDto = {
        associated_product_id: this.retirement_goal_calculator_dto.associated_product_id,
        defined_by: 'contribution',
        issued_age: this.retirement_goal_calculator_dto.issued_age,
        retirement_age_adjusted: this.retirement_goal_calculator_dto.retirement_age_adjusted,
        initial_amount: this.initial_amount,
        monthly_required_amount: 0,
        monthly_pension_adjusted: 0,
      };
      const { retirement_calculation_data } = await this
        .get_retirement_investor_goal_calculator_query.execute(get_min_pension_dto);
      this.min_pension = Math.ceil(retirement_calculation_data.monthly_pension_adjusted);
      if (!this.parseCurrencyToNumber(this.monthly_desired_pension)) {
        this.monthly_desired_pension = String(this.min_pension);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_min_pension'));
    } finally {
      this.is_min_pension_loading = false;
    }
  };

  loadMonthlyRequiredAmount = async () => {
    try {
      this.is_loading = true;
      this.retirement_goal_calculator_dto.defined_by = (this.is_define_by_contribution) ? 'contribution' : 'pension';
      this.retirement_goal_calculator_dto.monthly_pension_adjusted = (this
        .is_define_by_contribution) ? 0 : this.parseCurrencyToNumber(this.monthly_desired_pension);
      this.retirement_goal_calculator_dto.monthly_required_amount = (this
        .is_define_by_contribution) ? this.parseCurrencyToNumber(this.contribution) : 0;
      const { retirement_calculation_data } = await this
        .get_retirement_investor_goal_calculator_query.execute(this.retirement_goal_calculator_dto);

      const {
        monthly_pension_adjusted,
        monthly_required_amount,
        accumulated_amount,
        retirement_range_adjusted,
        pension_range_adjusted,
      } = retirement_calculation_data;
      if (this.is_define_by_contribution) {
        this.calculated_pension = monthly_pension_adjusted;
        this.target_amount_by_contribution = accumulated_amount;
        this.retirement_range_adjusted_by_contribution = retirement_range_adjusted;
      } else {
        this.monthly_required_amount = monthly_required_amount;
        this.target_amount_by_pension = accumulated_amount;
        this.retirement_range_adjusted_by_pension = retirement_range_adjusted;
      }
      this.pension_range_adjusted = pension_range_adjusted;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_monthly_required_amount'));
    } finally {
      this.is_loading = false;
    }
  };

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    const define_information: DefineByEntity = {
      is_defined_by_contribution: this.is_define_by_contribution,
      pension_range_adjusted: this.pension_range_adjusted,
      by_pension: {
        contribution: this.parseCurrencyToNumber(this.monthly_desired_pension),
        you_must_save: this.monthly_required_amount,
        accumulated_amount: this.target_amount_by_pension,
        retirement_range_adjusted: this.retirement_range_adjusted_by_pension,
      },
      by_contribution: {
        contribution: this.parseCurrencyToNumber(this.contribution),
        you_must_save: this.calculated_pension,
        accumulated_amount: this.target_amount_by_contribution,
        retirement_range_adjusted: this.retirement_range_adjusted_by_contribution,
      },
    };
    this.setDefineByInformation(define_information);
    this.view.$emit('nextStep');
  }
}
