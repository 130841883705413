






































































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditRetirementFundDefineByViewModel from
  '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-retirement-fund-goal-plan/edit-retirement-fund-define-by-view-model';

@Component({
  name: 'EditRetirementFundDefineBy',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class EditRetirementFundDefineBy extends Vue {
  edit_retirement_fund_define_by_view_model = Vue.observable(
    new EditRetirementFundDefineByViewModel(this),
  );

  async mounted() {
    await this.edit_retirement_fund_define_by_view_model.initialize();
  }
}
